import { FunctionComponent, useEffect, useRef } from 'react'

import { CardType } from '../ui-library/types/card'
import { RowFactory } from '../row/row-factory'
import { css } from '@emotion/css'
import { ResolvedReference } from '../ui-library'
import { cn } from '../utils/shadcn'
import { Mode, useEngine } from '../contexts/engine-context'
import { HttpClient } from '../clients/http-client'

type Props = {
  card: CardType
}

export const CardFactory: FunctionComponent<Props> = ({ card }) => {
  const { rows, theme, history } = card
  const { mode, domain, getJWTToken } = useEngine()

  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mode === Mode.LIVE && history && cardRef.current) {
      HttpClient.post(
        `${domain}/me/actions/history`,
        {
          body: cardRef.current.outerHTML,
        },
        {
          headers: {
            Authorization: `Bearer ${getJWTToken()}`,
          },
        }
      )
    }
  }, [])

  return (
    <div
      className={cn(
        theme.borderRadius,
        theme.borderWidth,
        theme.borderStyle,
        'p-4'
      )}
      style={{
        backgroundColor: (theme.backgroundColor as ResolvedReference).value,
      }}
      ref={cardRef}
    >
      {rows.map((row, index) => (
        <RowFactory row={row} key={index} />
      ))}
    </div>
  )
}
