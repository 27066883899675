import { Button as BaseButton } from '../../components/ui/button'
import React, { useState } from 'react'

import { css } from '@emotion/css'
import { ButtonIconPosition, ButtonType } from '../types/button'
import { cn } from '../../utils/shadcn'
import { ResolvedButtonStyleReference } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { Mode, useEngine } from '../../contexts/engine-context'
import { HttpClient } from '../../clients/http-client'
import { replaceFields } from '../utils/json'
import { apply } from 'json-logic-js'
import { icons } from 'lucide-react'

interface ButtonProps {
  item?: ButtonType
}

export const Button: React.FunctionComponent<ButtonProps> = ({ item }) => {
  const { trigger, watch } = useFormContext()
  const {
    pageFields,
    mode,
    domain,
    getJWTToken,
    enginePath: prefix,
  } = useEngine()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = `${location.pathname.replace(prefix, '')}${
    location.search
  }`
  const [isLoading, setIsLoading] = useState(false)

  const values = watch()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  if (!item) {
    return null
  }

  const { theme, text, icon } = item
  const style = theme.style as ResolvedButtonStyleReference

  const className = theme
    ? cn(
        style.borderRadius,
        style.borderStyle,
        style?.borderWidth,
        css`
          padding-top: ${style?.paddingTop}px !important;
          padding-right: ${style?.paddingRight}px !important;
          padding-bottom: ${style?.paddingBottom}px !important;
          padding-left: ${style?.paddingLeft}px !important;

          background-color: ${style.style === 'textButton'
            ? 'transparent'
            : style.state.default.backgroundColor};
          color: ${style.state.default.textColor};
          border-color: ${style.style === 'textButton'
            ? 'transparent'
            : style.state.default.borderColor};
          &:hover {
            background-color: ${style.style === 'textButton'
              ? 'transparent'
              : style.state.hover.backgroundColor};
            color: ${style.state.hover.textColor};
            border-color: ${style.style === 'textButton'
              ? 'transparent'
              : style.state.hover.borderColor};
          }
        `
      )
    : ''

  const handleClick = () => {
    const currentPageFields = pageFields[currentPath].map(
      ({ field, cid }) => `${cid}.${field.id}`
    )

    const validUrl = item.urls.find(
      (item) => !item.jsonLogicCondition || apply(item.jsonLogicCondition)
    )

    if (!validUrl) {
      console.error('Invalid configuartion')
    }

    trigger(currentPageFields).then((isValid) => {
      if (isValid) {
        if (validUrl?.actions?.length && mode === Mode.LIVE) {
          const actions = validUrl.actions.map((action) =>
            replaceFields(action, values[cid])
          )

          setIsLoading(true)

          HttpClient.post(
            `${domain}/me/actions`,
            { actions },
            {
              headers: {
                Authorization: `Bearer ${getJWTToken()}`,
              },
            }
          )
            .then(() => {
              navigate(`${prefix}${validUrl.path}`)
            })
            .finally(() => setIsLoading(false))
        } else {
          navigate(`${prefix}${validUrl.path}`)
        }
      }
    })
  }

  return (
    <BaseButton
      className={className}
      loading={isLoading}
      onClick={handleClick}
      leadIcon={
        icon?.position === ButtonIconPosition.START && icon?.name
          ? icons[icon?.name]
          : undefined
      }
      tailIcon={
        icon?.position === ButtonIconPosition.END && icon?.name
          ? icons[icon?.name]
          : undefined
      }
    >
      {text}
    </BaseButton>
  )
}
