import { FunctionComponent, useEffect } from 'react'
import { ServicePageType, ServiceResponseDto } from '../ui-library/types/page'
import { useEngine } from '../contexts/engine-context'
import { useFormContext } from 'react-hook-form'
import axios from 'axios'
import { replaceFields } from '../ui-library/utils/json'
import { useLocation } from 'react-router-dom'
import { LoadingSpinner } from '../components/ui/loading-spinner'

type Props = {
  page: ServicePageType
}

export const ServicePage: FunctionComponent<Props> = ({ page }) => {
  const { domain, getJWTToken } = useEngine()
  const { watch } = useFormContext()
  const location = useLocation()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const values = watch()

  useEffect(() => {
    axios
      .post<ServiceResponseDto>(
        `${domain}/integration-services/${page.serviceRequest.id}`,
        replaceFields(page.serviceRequest.payload, values[cid]),
        {
          headers: {
            Authorization: `Bearer ${getJWTToken()}`,
          },
        }
      )
      .then((response) => {
        window.location.href = response.data.url
      })
  }, [])

  return (
    <div className="flex items-center justify-center h-[calc(100vh-132px-2rem)]">
      <LoadingSpinner size={32} />
    </div>
  )
}
