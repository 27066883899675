import { FunctionComponent, useMemo, useState } from 'react'
import { HttpClient } from '../clients/http-client'
import { useEngine } from '../contexts/engine-context'
import {
  GroupedHistoryCards,
  HistoryCard,
  HistoryResponseDto,
} from '../ui-library/types/history'
import { last } from 'lodash'
import dayjs from 'dayjs'
import InfiniteScroll from 'react-infinite-scroll-component'

const PAGE_SIZE = 5

export const HistoryFactory: FunctionComponent = () => {
  const { domain, getJWTToken, labels } = useEngine()
  const [rawItems, setRawItems] = useState<HistoryCard[]>([])
  const [hasMore, setHasMore] = useState(true)

  const historyItems = useMemo<GroupedHistoryCards>(
    () =>
      rawItems.reduce((accumulator, item) => {
        const date = dayjs(item.createdAt).format('DD/MM/YYYY')
        accumulator[date] = accumulator[date]?.length
          ? [...accumulator[date], item]
          : [item]

        return accumulator
      }, {}),
    [rawItems]
  )

  const handleLoadMore = () => {
    HttpClient.get<HistoryResponseDto>(`${domain}/me/actions/history`, {
      params: { createdBefore: last(rawItems)?.createdAt },
      headers: {
        Authorization: `Bearer ${getJWTToken()}`,
      },
    }).then((response) => {
      setRawItems([...rawItems, ...response])
      setHasMore(response.length < PAGE_SIZE)
    })
  }

  return rawItems.length > 0 ? (
    <InfiniteScroll
      dataLength={rawItems.length}
      next={handleLoadMore}
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
      hasMore={hasMore}
      loader={<span className="text-sm">{labels.historyLoading}</span>}
      scrollableTarget="scrollableDiv"
    >
      {Object.entries(historyItems).map(([date, cards]) => (
        <div className="space-y-4">
          <span className="italic text-sm text-right">
            {labels.historySubmissionDate} {date}
          </span>
          {cards.map((card) => (
            <div dangerouslySetInnerHTML={{ __html: card.body }} />
          ))}
        </div>
      ))}
    </InfiniteScroll>
  ) : (
    <span className="text-sm">{labels.historyNoItems}</span>
  )
}
