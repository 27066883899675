import axios from 'axios'
import axiosRetry from 'axios-retry'

const client = axios.create({
  baseURL: 'http://localhost:4000',
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosRetry(client, { retries: 3 })

export class HttpClient {
  static async get<T>(url: string, options?: any) {
    const response = await client.get<T>(url, options)

    return response.data
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await client.post<T>(url, data, options)

    return response
  }

  static async put<T>(url: string, data: unknown) {
    const response = await client.put<T>(url, data)

    return response
  }

  static async patch<T>(url: string, data: unknown) {
    const response = await client.patch<T>(url, data)

    return response
  }

  static async delete<T>(url: string) {
    const response = await client.delete<T>(url)

    return response
  }
}
