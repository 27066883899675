import React from 'react'

import { RowImageType as Row } from '../../types/row'
import { ResolvedReference } from '../../types'

type Props = {
  row: Row
  selectImage?: () => void
}

export const RowImage: React.FunctionComponent<Props> = ({
  row,
  selectImage,
}) => {
  const { image } = row

  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  return (
    <div className="flex-1 flex flex-col" style={{ backgroundColor }}>
      <div onClick={selectImage}>
        <img className="w-full" src={image.source} alt={image.alternateText} />
      </div>
    </div>
  )
}
