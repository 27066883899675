import { ArrowLeft, House } from 'lucide-react'
import { FunctionComponent } from 'react'
import { Link, To } from 'react-router-dom'
import { useEngine } from '../contexts/engine-context'

export const NavigationBar: FunctionComponent = () => {
  const { enginePath: prefix } = useEngine()
  return (
    <div className="flex items-center divide-x">
      <Link className="px-2" to={`${prefix}/home`}>
        <House />
      </Link>
      <Link className="px-2" to={-1 as To}>
        <ArrowLeft />
      </Link>
    </div>
  )
}
