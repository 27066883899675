import { FunctionComponent, useEffect, useMemo } from 'react'
import { PageType } from '../ui-library/types/page'
import { ExternalPage } from './external-page'
import { InternalPage } from './internal-page'
import dayjs from 'dayjs'
import axios from 'axios'
import { Mode, useEngine } from '../contexts/engine-context'
import { ServicePage } from './service-page'
import { useLocation } from 'react-router-dom'

export const PageFactory: FunctionComponent = () => {
  const location = useLocation()
  const { mode, domain, getJWTToken, enginePath, config } = useEngine()

  const path = `${location.pathname.replace(enginePath, '')}${location.search}`

  const page = config[path]

  const { type } = page

  // TODO: Redirect if required fields arent completed

  useEffect(() => {
    if (page && mode === Mode.LIVE) {
      const enhacedAnalyticsObject = {
        ...page.analyticsTag,
        seqId: dayjs().unix(),
      }

      axios
        .post(`${domain}/analytics/page-views`, enhacedAnalyticsObject, {
          headers: {
            Authorization: `Bearer ${getJWTToken()}`,
          },
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [])

  const component = useMemo(() => {
    switch (type) {
      case PageType.EXTERNAL:
        return <ExternalPage page={page} />
      case PageType.INTERNAL:
        return <InternalPage page={page} />
      case PageType.SERVICE:
        return <ServicePage page={page} />
    }
  }, [page])

  return <div className="flex-1 flex flex-col py-8">{component}</div>
}
