import { FunctionComponent, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorFallback } from '../../engine/components/error-fallback'
import {
  RowButtons,
  RowField,
  RowHistory,
  RowId,
  RowLabel,
  RowType,
} from '../ui-library'

import { Row3Boxes } from '../ui-library/components/rows/row-3-boxes'
import { Row2Boxes } from '../ui-library/components/rows/row-2-boxes'
import { RowAccordion } from '../ui-library/components/rows/row-accordion'
import { RowCheckbox } from '../ui-library/components/rows/row-checkbox'
import { RowSection } from '../ui-library/components/rows/row-section'
import { RowDivider } from '../ui-library/components/rows/row-divider'
import { RowImage } from '../ui-library/components/rows/row-image'

type Props = {
  row: RowType
}

export const RowFactory: FunctionComponent<Props> = ({ row }) => {
  const { layoutId } = row

  const component = useMemo(() => {
    switch (layoutId) {
      case RowId.Label:
        return <RowLabel row={row} />
      case RowId.ThreeBoxes:
        return <Row3Boxes row={row} />
      case RowId.Buttons:
        return <RowButtons row={row} />
      case RowId.Field:
        return <RowField row={row} />
      case RowId.TwoBoxes:
        return <Row2Boxes row={row} />
      case RowId.Accordion:
        return <RowAccordion row={row} />
      case RowId.Checkbox:
        return <RowCheckbox row={row} />
      case RowId.History:
        return <RowHistory row={row} />
      case RowId.Section:
        return <RowSection row={row} />
      case RowId.Separator:
        return <RowDivider row={row} />
      case RowId.Image:
        return <RowImage row={row} />
    }
  }, [layoutId])

  return (
    <div
      style={{
        paddingTop: `${row.theme?.paddingTop}px`,
        paddingRight: `${row.theme?.paddingRight}px`,
        paddingBottom: `${row.theme?.paddingBottom}px`,
        paddingLeft: `${row.theme?.paddingLeft}px`,
      }}
    >
      {component}
    </div>
  )
}
