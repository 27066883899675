import React from 'react'

import { RowHistoryType as Row } from '../../types/row'
import { ResolvedReference } from '../../types'
import { useEngine } from '../../../contexts/engine-context'
import { useFormContext } from 'react-hook-form'

type Props = {
  row: Row
}

export const RowHistory: React.FunctionComponent<Props> = ({ row }) => {
  const { history: html } = row

  const { nunjucks } = useEngine()
  const { watch } = useFormContext()

  const values = watch()

  const renderedHtml = React.useMemo(() => {
    return nunjucks.renderString(html.text, { field: values })
  }, [values])

  React.useEffect(() => {}, [])

  return (
    <div
      style={{
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
      dangerouslySetInnerHTML={{ __html: renderedHtml }}
    />
  )
}
