import { ButtonEditType, ButtonType } from './button'
import {
  FieldCheckbox,
  FieldCheckboxEditType,
  GeneralFieldEditType,
  GeneralFieldType,
} from './field'
import { ImageEditType, ImageType } from './image'
import { LabelEditType, LabelType } from './label'
import { LineEditType, LineType } from './line'
import { ColorReference } from './theme'

export enum RowId {
  Label = 'label',
  ThreeBoxes = '3boxes',
  TwoBoxes = '2boxes',
  Buttons = 'buttons',
  Field = 'field',
  Image = 'image',
  Separator = 'separator',
  History = 'history',
  Accordion = 'accordion',
  Checkbox = 'checkbox',
  Section = 'section',
}

export enum RowContentJustify {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export type RowBaseType = {
  layoutId: RowId
  justify: RowContentJustify
  theme?: RowTheme
}

export type RowBaseEditType = {
  id?: string
  layoutId: RowId
  justify: RowContentJustify
  theme?: RowTheme
}

export type RowTheme = {
  backgroundColor: ColorReference
  paddingTop: number
  paddingRight: number
  paddingBottom: number
  paddingLeft: number
}

export type RowLabelType = RowBaseType & {
  layoutId: RowId.Label
  label?: LabelType
}

export type RowLabelEditType = RowBaseEditType & {
  layoutId: RowId.Label
  label?: LabelEditType
}

export type RowHistoryType = RowBaseType & {
  layoutId: RowId.History
  history?: LabelType
}

export type RowHistoryEditType = RowBaseEditType & {
  layoutId: RowId.History
  history?: LabelEditType
}

export type RowThreeBoxesType = RowBaseType & {
  layoutId: RowId.ThreeBoxes
  box1Text?: LabelType
  box2Text?: LabelType
  box3Text?: LabelType
}

export type RowThreeBoxesEditType = RowBaseEditType & {
  layoutId: RowId.ThreeBoxes
  box1Text?: LabelEditType
  box2Text?: LabelEditType
  box3Text?: LabelEditType
}

export type RowTwoBoxesType = RowBaseType & {
  layoutId: RowId.TwoBoxes
  box1Text?: LabelType
  box2Text?: LabelType
}

export type RowTwoBoxesEditType = RowBaseEditType & {
  layoutId: RowId.TwoBoxes
  box1Text?: LabelEditType
  box2Text?: LabelEditType
}

export type RowButtonsType = RowBaseType & {
  layoutId: RowId.Buttons
  button1?: ButtonType
  button2?: ButtonType
}

export type RowButtonsEditType = RowBaseEditType & {
  layoutId: RowId.Buttons
  button1?: ButtonEditType
  button2?: ButtonEditType
}

export type RowFieldType = RowBaseType & {
  layoutId: RowId.Field
  title?: LabelType
  field?: GeneralFieldType
  description?: LabelType
}

export type RowFieldEditType = RowBaseEditType & {
  layoutId: RowId.Field
  field?: GeneralFieldEditType
  title?: LabelEditType
  description?: LabelEditType
}

export type RowCheckboxType = RowBaseType & {
  layoutId: RowId.Checkbox
  title?: LabelType
  field?: FieldCheckbox
  description?: LabelType
}

export type RowCheckboxEditType = RowBaseEditType & {
  layoutId: RowId.Checkbox
  title?: LabelEditType
  field?: FieldCheckboxEditType
  description?: LabelEditType
}

export type RowAccordionType = RowBaseType & {
  layoutId: RowId.Accordion
  title?: LabelType
  description?: LabelType
  line?: LineType
}

export type RowAccordionEditType = RowBaseEditType & {
  layoutId: RowId.Accordion
  title?: LabelEditType
  description?: LabelEditType
  line?: LineEditType
}

export type RowImageType = RowBaseType & {
  layoutId: RowId.Image
  image?: ImageType
}

export type RowImageEditType = RowBaseEditType & {
  layoutId: RowId.Image
  image?: ImageEditType
}

export type RowSeparatorType = RowBaseType & {
  layoutId: RowId.Separator
  line?: LineType
}

export type RowSeparatorEditType = RowBaseEditType & {
  layoutId: RowId.Separator
  line?: LineEditType
}

export type RowSectionType = RowBaseType & {
  layoutId: RowId.Section
  title?: LabelType
  description?: LabelType
  verticalLine?: LineType
  horizontalLine?: LineType
}

export type RowSectionEditType = RowBaseEditType & {
  layoutId: RowId.Section
  title?: LabelEditType
  description?: LabelEditType
  verticalLine?: LineEditType
  horizontalLine?: LineEditType
}

export type RowType =
  | RowLabelType
  | RowThreeBoxesType
  | RowTwoBoxesType
  | RowButtonsType
  | RowFieldType
  | RowImageType
  | RowSeparatorType
  | RowHistoryType
  | RowAccordionType
  | RowCheckboxType
  | RowSectionType

export type RowEditType =
  | RowLabelEditType
  | RowThreeBoxesEditType
  | RowTwoBoxesEditType
  | RowButtonsEditType
  | RowFieldEditType
  | RowImageEditType
  | RowSeparatorEditType
  | RowHistoryEditType
  | RowAccordionEditType
