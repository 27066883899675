import React from 'react'
import { cn } from '../../utils/shadcn'

type Props = {
  className?: string
  color?: string
  style?: string
  direction?: 'horizontal' | 'vertical'
  width?: number
}

export const Divider: React.FunctionComponent<Props> = ({
  className = 'my-2',
  color,
  style = 'solid',
  direction = 'horizontal',
  width,
}) => {
  return (
    <div className={cn(`relative`, className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className={cn(
            'border-gray-300',
            `border-${style}`,
            direction === 'horizontal' ? 'w-full border-t' : 'h-full border-l'
          )}
          style={{ borderColor: color, borderWidth: `${width}px` }}
        />
      </div>
    </div>
  )
}
