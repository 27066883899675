import { LOCAL_STORAGE_KEYS } from '../constants'
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { Config } from '../engine/ui-library/types/config'
import { UIService } from '../services'
import LoadingOptions from '../components/LoadingOptions'
import { EngineLayout, Mode } from '../engine/contexts/engine-context'
import { getToken } from '../helpers'
import { PortalConfig } from '../helpers/useConfig'

interface Props extends PropsWithChildren {
  appConfig: PortalConfig
}

export const EngineConfigProvider: FunctionComponent<Props> = ({
  appConfig,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState<Config>(undefined)

  useEffect(() => {
    setIsLoading(true)
    UIService.fetchUserOptions().then((config) => {
      setConfig(config)
      setIsLoading(false)
    })
  }, [])

  return isLoading ? (
    <LoadingOptions loading />
  ) : (
    <EngineLayout
      labels={{
        historyNoItems: 'No items',
        historySubmissionDate: 'Submission date',
        historyLoading: 'Loading...',
      }}
      config={config}
      locale="en_GB"
      enginePath="/dashboard"
      mode={Mode.LIVE}
      domain="https://api.dev.demo-css.com"
      getJWTToken={() => getToken(LOCAL_STORAGE_KEYS.AUTH).accessToken}
    />
  )
}
