import React from 'react'

import { RowThreeBoxesType as Row } from '../../types/row'
import { Label } from '../label'
import { ResolvedReference } from '../../types'

type Props = {
  row: Row
  selectBox1Text?: () => void
  selectBox2Text?: () => void
  selectBox3Text?: () => void
}

export const Row3Boxes: React.FunctionComponent<Props> = ({
  row,
  selectBox1Text,
  selectBox2Text,
  selectBox3Text,
}) => {
  const { box1Text, box2Text, box3Text } = row

  const justify = row.justify

  return (
    <div
      className="flex flex-row"
      style={{
        justifyContent: justify || 'space-between',
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
    >
      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox1Text}>
          <Label label={box1Text} />
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox2Text}>
          <Label label={box2Text} />
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox3Text}>
          <Label label={box3Text} />
        </div>
      </div>
    </div>
  )
}
