import { FunctionComponent, useEffect } from 'react'
import { ExternalPageTarget, ExternalPageType } from '../ui-library/types/page'

type Props = {
  page: ExternalPageType
}

export const ExternalPage: FunctionComponent<Props> = ({ page }) => {
  useEffect(() => {
    if (page.target === ExternalPageTarget._BLANK) {
      window.open(page.url, ExternalPageTarget._BLANK)
    } else {
      window.location.href = page.url
    }
  }, [])

  return null
}
