import React, { PropsWithChildren } from 'react'
import { Page } from '../types/engine'
import { Config } from '../ui-library/types/config'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getSeparators } from '../ui-library/utils/locale'
import { InternalPageType, PageType } from '../ui-library/types/page'
import { FieldType, GeneralFieldType, RowFieldType, RowId } from '../ui-library'
import { fieldValidationFactory } from './validation-factory'
import { FormProvider } from '../components/form-provider'
import { Outlet } from 'react-router-dom'
import dayjs from 'dayjs'
import nunjucks from 'nunjucks'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

export enum Mode {
  PREVIEW = 'preview',
  LIVE = 'live',
}

type Props = {
  locale: string
  mode: Mode
  domain: string
  enginePath: string
  getJWTToken: () => string
  config: Config
  labels: Record<string, string>
}

type State = {
  page: Page | undefined
  pageFields: Record<string, { field: GeneralFieldType; cid: string }[]>
  locale: string
  enginePath: string
  separators: {
    group: string
    decimal: string
  }
  fields: {
    field: FieldType
    cid: string
  }[]
  mode: Mode
  domain: string
  nunjucks: nunjucks.Environment
  getJWTToken: () => string
  config: Config
  labels: Record<string, string>
}

const initialContextState: State = {
  page: undefined,
  // pageFields: [],
  locale: 'en_US',
  enginePath: '',
  separators: {
    group: '.',
    decimal: ',',
  },
  fields: [],
  pageFields: {},
  mode: Mode.PREVIEW,
  domain: '',
  getJWTToken: () => '',
  nunjucks: new nunjucks.Environment(),
  config: {},
  labels: {},
}

const EngineContext = React.createContext(initialContextState)

const EngineProvider: React.FunctionComponent<Props & PropsWithChildren> = ({
  children,
  locale,
  mode,
  domain,
  getJWTToken,
  enginePath,
  config,
  labels,
}) => {
  const separators = React.useMemo(() => getSeparators(locale), [locale])

  const nunjucksInstance = React.useMemo(() => {
    const env = new nunjucks.Environment()

    env.addFilter('format_date', (value: string, format: string) => {
      // Implement your custom pipe logic here
      return dayjs(value).format(format || 'DD/MM/YYYY')
    })

    env.addFilter('format_currency', (value: number) => {
      // TODO: Retrieve currency from the context
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(value)
    })

    env.addFilter(
      'exists_in',
      (
        value: string | number | (string | number)[],
        lst: (string | number)[],
        method?: string
      ) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return lst.includes(value)
        }

        return value[method === 'all' ? 'every' : 'some']((v) =>
          lst.includes(v)
        )
      }
    )

    return env
  }, [])

  const pageFields = React.useMemo(
    () =>
      Object.entries(config)
        .filter(([, page]) => page.type === PageType.INTERNAL)
        .reduce(
          (accumulator, [key, page]: [string, InternalPageType]) => ({
            ...accumulator,
            [key]: page.content.main.cards.reduce(
              (accumulator, card) => [
                ...accumulator,
                ...card.rows
                  .filter(
                    (row) =>
                      row.layoutId === RowId.Field ||
                      row.layoutId === RowId.Checkbox
                  )
                  .map((row: RowFieldType) => ({
                    field: row.field,
                    cid:
                      new URLSearchParams(key.split('?')[1]).get('cid') ??
                      'default',
                  })),
              ],
              []
            ),
          }),
          {}
        ),
    [config]
  )

  const fields = React.useMemo<{ field: FieldType; cid: string }[]>(
    () =>
      Object.values(pageFields).reduce<{ field: FieldType; cid: string }[]>(
        (accumulator, values: { field: FieldType; cid: string }[]) => [
          ...accumulator,
          ...values.map((field) => ({
            field: field.field,
            cid: field.cid,
          })),
        ],
        []
      ),
    [pageFields]
  )

  const structuredFields = React.useMemo(
    () =>
      fields.reduce(
        (accumulator, field) => ({
          ...accumulator,
          [field.cid]: [...(accumulator[field.cid] ?? []), field.field],
        }),
        {}
      ),
    [fields]
  )

  const ValidationSchema = React.useMemo(
    () =>
      yup.object().shape(
        Object.entries(structuredFields).reduce(
          (accumulator, [key, fields]: [string, GeneralFieldType[]]) => ({
            ...accumulator,
            [key]: yup.object().shape(
              fields.reduce(
                (accumulator, field) => ({
                  ...accumulator,
                  [field.id]: fieldValidationFactory(field),
                }),
                {}
              )
            ),
          }),
          {}
        )
      ),
    [structuredFields]
  )

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(ValidationSchema),
    mode: 'onBlur',
  })

  return (
    <EngineContext.Provider
      value={{
        ...initialContextState,
        separators,
        pageFields,
        locale,
        mode,
        domain,
        nunjucks: nunjucksInstance,
        getJWTToken,
        enginePath,
        config,
        labels,
      }}
    >
      <FormProvider methods={methods}>{children}</FormProvider>
    </EngineContext.Provider>
  )
}

const EngineLayout: React.FunctionComponent<Props> = (props) => {
  return (
    <EngineProvider {...props}>
      <Outlet />
    </EngineProvider>
  )
}

const useEngine = () => {
  const context = React.useContext(EngineContext)

  if (!context) {
    throw Error('Use useEngine inside an <EngineProvider/>')
  }

  return context
}

export { EngineContext, EngineProvider, EngineLayout, useEngine }
