import React from 'react'

import { RowTwoBoxesType as Row } from '../../types/row'
import { Label } from '../label'
import { ResolvedReference } from '../../../ui-library/types'

type Props = {
  row: Row
  selectBox1Text?: () => void
  selectBox2Text?: () => void
}

export const Row2Boxes: React.FunctionComponent<Props> = ({
  row,
  selectBox1Text,
  selectBox2Text,
}) => {
  const { box1Text, box2Text } = row

  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  return (
    <div
      className="flex flex-row"
      style={{
        backgroundColor,
      }}
    >
      {box1Text && (
        <div onClick={selectBox1Text} className="flex-1">
          <Label label={box1Text} />
        </div>
      )}
      {box2Text && (
        <div onClick={selectBox2Text} className="flex-1">
          <Label label={box2Text} />
        </div>
      )}
    </div>
  )
}
