import React from 'react'

import { RowButtonsType as Row } from '../../types/row'
import { ResolvedReference } from '../../../ui-library/types'
import { cn } from '../../../utils/shadcn'
import { Button } from '../button'

type Props = {
  row: Row
}

export const RowButtons: React.FunctionComponent<Props> = ({ row }) => {
  const { justify, button1, button2 } = row

  return (
    <div
      className="flex flex-row justify-end space-x-2"
      style={{
        justifyContent: justify || 'start',
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
    >
      {row.button1 && (
        <div className={cn(button1?.hidden && 'hidden')}>
          <Button item={row.button1} />
        </div>
      )}
      {row.button2 && (
        <div className={cn(button2?.hidden && 'hidden')}>
          <Button item={row.button2} />
        </div>
      )}
    </div>
  )
}
