import React from 'react'

import { ResolvedReference, RowSeparatorType as Row } from '../../types'
import { Divider } from '../divider'

type Props = {
  select?: () => void
  row: Row
}

export const RowDivider: React.FunctionComponent<Props> = ({ row, select }) => {
  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  const color = (row.line.theme.color as ResolvedReference).value

  const style = row.line.theme.style

  return (
    <div
      className="flex-1 flex flex-col"
      style={{ backgroundColor }}
      onClick={select}
    >
      <Divider color={color} style={style} width={row.line.theme.width} />
    </div>
  )
}
