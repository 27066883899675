import React from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../components/ui/accordion'

import { ResolvedReference, RowAccordionType } from '../../types'
import { Label } from '../label'

type Props = {
  row: RowAccordionType
  selectTitle?: () => void
  selectDescription?: () => void
}

export const RowAccordion: React.FunctionComponent<Props> = ({
  row,
  selectTitle,
  selectDescription,
}) => {
  const { title, description } = row

  const backgroundColor = (row.theme?.backgroundColor as ResolvedReference)
    .value

  return (
    <Accordion
      type="single"
      style={{ backgroundColor }}
      collapsible
      className="w-full"
    >
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <span onClick={selectTitle}>
            <Label label={title} />
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <span
            className="text-base leading-7 text-gray-600"
            onClick={selectDescription}
          >
            <Label label={description} />
          </span>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
