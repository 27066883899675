const VARIABLE_REGEX = /\{\{\s*field\.(\w+)\s*\}\}/g

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceFields = <T>(object: T, values: any): T => {
  const string = JSON.stringify(object)

  const translatedString = string.replace(VARIABLE_REGEX, (match, fieldKey) => {
    return values?.[fieldKey] || ''
  })

  return JSON.parse(translatedString)
}
