import { JsonLogicCondition, LocalizedText, RenderedText } from "./common";
import { FieldStyleReference } from "./theme";

export enum FieldType {
  Text = "text",
  Checkbox = "checkbox",
  Select = "select",
  Date = "date",
  Number = "number",
  Amount = "amount",
}

export type FieldBaseType = {
  id: string;
  type: FieldType;
  theme: FieldStyleReference;
  hidden?: boolean;
  readOnly?: boolean;
  required?: boolean;
};

export type FieldBaseEditType = FieldBaseType;

export type FieldText = FieldBaseType & {
  type: FieldType.Text;
  maxLength?: number;
  minLength?: number;
  placeholder?: RenderedText;
  regex?: string;
  regexError?: RenderedText;
  value: string;
};

export type FieldTextEditType = FieldBaseEditType & {
  type: FieldType.Text;
  maxLength?: number;
  minLength?: number;
  placeholder?: LocalizedText;
  regex?: string;
  regexError?: LocalizedText;
  value: string;
};

export enum FieldCheckboxStyle {
  Checkbox = "checkbox",
  Toggle = "toggle",
}

export type FieldCheckbox = FieldBaseType & {
  type: FieldType.Checkbox;
  reset?: boolean;
  checkedValue?: string;
  uncheckedValue?: string;
  style: FieldCheckboxStyle;
  value?: boolean;
};

export type FieldCheckboxEditType = FieldBaseEditType & {
  type: FieldType.Checkbox;
  reset?: boolean;
  checkedValue?: string;
  uncheckedValue?: string;
  style: FieldCheckboxStyle;
  value?: boolean;
};

export enum FieldNumericStyle {
  Field = "field",
  Slider = "slider",
}

export type FieldNumber = FieldBaseType & {
  type: FieldType.Number;
  calculated?: boolean;
  maxValue?: number | string;
  minValue?: number | string;
  style: FieldNumericStyle;
  round?: boolean;
  value?: string;
};

export type FieldNumberEditType = FieldBaseEditType & {
  type: FieldType.Number;
  calculated?: boolean;
  maxValue?: number | string;
  minValue?: number | string;
  round?: boolean;
  style: FieldNumericStyle;
  value?: string;
};

export type FieldAmount = FieldBaseType & {
  type: FieldType.Amount;
  calculated?: boolean;
  currencyCode: string;
  maxValue?: number | string;
  minValue?: number | string;
  value?: string;
  round?: boolean;
  style: FieldNumericStyle;
};

export type FieldAmountEditType = FieldBaseEditType & {
  type: FieldType.Amount;
  calculated?: boolean;
  currencyCode: string;
  maxValue?: number | string;
  minValue?: number | string;
  value?: string;
  round?: boolean;
  style: FieldNumericStyle;
};

export enum FieldSelectStyle {
  Dropdown = "dropdown",
  RadioButtons = "radioButtons",
}

export type SelectValueType = {
  key: string;
  jsonLogicCondition?: JsonLogicCondition;
  value: RenderedText;
};

export type FieldSelect = FieldBaseType & {
  type: FieldType.Select;
  maxValues?: number;
  values: SelectValueType[];
  style: FieldSelectStyle;
  value?: string;
  placeholder?: RenderedText;
};

export type SelectValueEditType = {
  id: string;
  key: string;
  jsonLogicCondition?: JsonLogicCondition;
  value: LocalizedText;
};

export type FieldSelectEditType = FieldBaseEditType & {
  type: FieldType.Select;
  maxValues?: number;
  values: SelectValueEditType[];
  style: FieldSelectStyle;
  value?: string;
  placeholder?: LocalizedText;
  theme: {
    style: {
      $ref: string;
    };
  };
};

export enum ExcludedDateType {
  Holidays = "holidays",
  Weekends = "weekends",
  Today = "today",
  Tomorrow = "tomorrow",
  Yesterday = "yesterday",
}

export type FieldDate = FieldBaseType & {
  type: FieldType.Date;
  excluded?: ExcludedDateType[];
  excludedDates?: number[];
  format?: string;
  maxDate?: string;
  minDate?: string;
  value?: string;
};

export type FieldDateEditType = FieldBaseEditType & {
  type: FieldType.Date;
  excluded?: ExcludedDateType[];
  excludedDates?: number[];
  format?: string;
  maxDate?: string;
  minDate?: string;
  value?: string;
};

export type GeneralFieldType =
  | FieldText
  | FieldCheckbox
  | FieldSelect
  | FieldDate
  | FieldNumber
  | FieldAmount;

export type GeneralFieldEditType =
  | FieldTextEditType
  | FieldCheckboxEditType
  | FieldSelectEditType
  | FieldDateEditType
  | FieldNumberEditType
  | FieldAmountEditType;
