import { FunctionComponent } from 'react'
import { MainContent } from '../../content/main-content'
import { InternalPageType } from '../../ui-library/types/page'

type Props = {
  page: InternalPageType
}

export const PageLayoutMain: FunctionComponent<Props> = ({ page }) => {
  const { content } = page
  const { main } = content

  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <MainContent content={main} />
      </div>
    </div>
  )
}
