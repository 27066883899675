import { FunctionComponent } from 'react'

import { CardFactory } from '../card/card-factory'
import { MainContentType } from '../ui-library/types/page'
import { useLocation } from 'react-router-dom'
import { useEngine } from '../contexts/engine-context'
import { HistoryFactory } from '../history/history-factory'

type Props = {
  content: MainContentType
}

export const MainContent: FunctionComponent<Props> = ({ content }) => {
  const { cards, columns } = content
  const { key, pathname } = useLocation()
  const { enginePath } = useEngine()

  return (
    <div
      className="gap-4 grid"
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {pathname === `${enginePath}/history` ? (
        <HistoryFactory />
      ) : (
        cards.map((card, index) => (
          <CardFactory card={card} key={`${key}-${index}`} />
        ))
      )}
    </div>
  )
}
