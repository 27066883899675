import { Field, Formik } from 'formik'
import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import {
  Caption,
  Heading,
  InputFieldContainer,
  StyledCheckBoxLabel,
  StyledFlexRow,
  StyledFlexColumn,
  StyledForm,
  StyledFormButton,
  LinkButton,
} from '../components/commonStyles'
import Loader from '../components/Loader'
import * as Yup from 'yup'
import { ROUTES } from '../constants'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { authService } from '../services'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import { capitalize, getErrorMessage, isLoggedIn } from '../helpers'

interface Props {
  t: TFunction
}

interface MaskedId {
  email?: string
  phone?: string
}

const getAvailableChannels = (otpChannels: MaskedId) => Object.keys(otpChannels)

const StyledMfaCaption = styled(Caption)`
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: var(--pages-mainContainer-primaryTextColor);
`

const OtpChannel: React.FC<Props> = ({ t }) => {
  const navigate = useNavigate()
  const { state } = useLocation() as { state: { otpChannels: MaskedId } }

  const { auth } = authService

  if (auth && isLoggedIn(auth)) {
    navigate(ROUTES.ENGINE_DASHBOARD)
  }

  if (!state) {
    navigate(ROUTES.LOGIN)
  }

  const channels = getAvailableChannels(state.otpChannels)

  const { addToast } = useMessages()

  const validationSchema = Yup.object({
    channel: Yup.string().oneOf(['email', 'phone']),
  })

  const handleSubmit = (
    values: { channel: 'email' | 'phone' },
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .sendOtp(values.channel)
      .then(() => {
        setSubmitting(false)
        addToast(MESSAGE_STATUS.SUCCESS, t('mfaChannel.204', ''))
        navigate(ROUTES.OTP_CODE, {
          state: { channel: values.channel },
        })
      })
      .catch((e) => {
        setSubmitting(false)
        const errorMessage = getErrorMessage(e, 'mfaChannel')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  return (
    <StyledFlexColumn
      align="center"
      justify="center"
      margin="60px auto"
      padding="0 20px"
    >
      <Heading
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t('mfaChannel.heading', ''),
        }}
      />
      <Caption
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t('mfaChannel.caption', ''),
        }}
      />
      <Formik
        initialValues={{ channel: channels[0] }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <StyledForm onSubmit={formik.handleSubmit} textAlign="center">
            <InputFieldContainer>
              {channels?.length > 1 ? (
                <React.Fragment>
                  <StyledMfaCaption
                    dangerouslySetInnerHTML={{
                      __html: t('mfaChannel.channelSelectionText', ''),
                    }}
                  />
                  <div role="group" aria-labelledby="channel-radio-group">
                    <StyledFlexColumn padding="8px" align="start">
                      {channels?.map((channel) => (
                        <StyledFlexRow padding="6px 8px" key={channel}>
                          <Field
                            type="checkbox"
                            name="channel"
                            value={channel}
                            style={{
                              accentColor:
                                'var(--pages-mainContainer-primaryTextColor)',
                            }}
                            onChange={(e) => {
                              formik.setFieldValue('channel', e.target.value)
                            }}
                            checked={formik.values.channel === channel}
                          />
                          <StyledCheckBoxLabel fontSize="16px">
                            {t([`mfaChannel.${channel}Label`, ''], {
                              [`masked${capitalize(channel)}`]:
                                state?.otpChannels?.[channel],
                            })}
                          </StyledCheckBoxLabel>
                        </StyledFlexRow>
                      ))}
                    </StyledFlexColumn>
                  </div>
                </React.Fragment>
              ) : (
                <StyledMfaCaption
                  dangerouslySetInnerHTML={{
                    __html: t(['mfaChannel.noSelectionText', ''], {
                      maskedPhoneOrEmail: state?.otpChannels?.[channels[0]],
                    }),
                  }}
                />
              )}
            </InputFieldContainer>

            <div>
              <StyledFormButton
                type="submit"
                id="submit"
                disabled={!formik.isValid}
              >
                {formik.isSubmitting ? (
                  <Loader />
                ) : (
                  <span>{t('mfaChannel.getOtpButton', '')}</span>
                )}
              </StyledFormButton>
            </div>
          </StyledForm>
        )}
      </Formik>

      <Link to={ROUTES.LOGIN}>
        <LinkButton
          id="mfa-btn"
          className="buttons-linkColor"
          margin="40px 0 0 0"
        >
          {t('mfaChannel.backButton', '')}
        </LinkButton>
      </Link>
    </StyledFlexColumn>
  )
}

export default withTranslation()(OtpChannel)
