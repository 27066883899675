import { Checkbox } from '../../../components/ui/checkbox'
import React from 'react'

import { cn } from '../../../../engine/utils/shadcn'
import { RowCheckboxType as Row } from '../../../ui-library/types'
import { Label } from '../label'
import { Label as FieldLabel } from '../../../components/ui/label'

import {
  FieldCheckboxStyle,
  ResolvedFieldStyleReference,
  ResolvedReference,
} from '../../../ui-library/types'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { css } from '@emotion/react'
import { FormField } from '../../../components/ui/form'
import { useLocation } from 'react-router-dom'
import ErrorMessage from '../error-message'
import { useFormContext } from 'react-hook-form'

type Props = {
  row: Row
}

export const RowCheckbox: React.FunctionComponent<Props> = ({ row }) => {
  const { justify, field: item } = row

  const { id } = item

  const location = useLocation()
  const { trigger } = useFormContext()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const dataKey = `${cid}.${id}`

  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  const checkboxClassname = css`
    &[data-state='checked'] {
      background-color: ${(item.theme as ResolvedFieldStyleReference).style
        .borderColor.value};
    }
  `

  const handleBlur = async (
    key: string,
    value: string | number | string[] | number[] | boolean
  ) => {
    const isValid = await trigger(key)
    if (isValid) {
      //   EngineService.saveField(key, value)
    }
  }

  return (
    <div>
      <div
        className="flex flex-row justify-end space-x-2"
        style={{
          justifyContent: justify || 'start',
          backgroundColor,
        }}
      >
        <div className="items-top space-y-2">
          <FormField
            name={dataKey}
            render={({ field }) => (
              <div className={'flex items-center space-x-2'}>
                {!row.field.hidden && row.field.style === 'checkbox' && (
                  <Checkbox
                    id={row.field.id}
                    style={{
                      borderColor: (item.theme as ResolvedFieldStyleReference)
                        .style.borderColor.value,
                    }}
                    className="bg-white"
                    color={
                      (item.theme as ResolvedFieldStyleReference).style
                        .borderColor.value
                    }
                    checked={field.value === item.checkedValue}
                    onBlur={() => handleBlur(dataKey, field.value)}
                    onCheckedChange={(value) => {
                      field.onChange(
                        value ? item.checkedValue : item.uncheckedValue
                      )
                      handleBlur(dataKey, field.value)
                    }}
                  />
                )}

                {row.field.style === 'checkbox' && (
                  <label className="cursor-pointer" htmlFor={row.field.id}>
                    <Label label={row.title} />
                  </label>
                )}

                {item.style === FieldCheckboxStyle.Toggle && (
                  <>
                    <SwitchPrimitives.Root
                      className={cn(
                        'shadow-inner peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-input',
                        checkboxClassname
                      )}
                      checked={field.value === item.checkedValue}
                      onCheckedChange={(value) =>
                        field.onChange(
                          value ? item.checkedValue : item.uncheckedValue
                        )
                      }
                    >
                      <SwitchPrimitives.Thumb
                        className={cn(
                          'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
                        )}
                      />
                    </SwitchPrimitives.Root>
                    <FieldLabel htmlFor={row.field.id}>
                      <Label label={row.title} />
                    </FieldLabel>
                  </>
                )}
              </div>
            )}
          />
          <div className="grid gap-1.5 leading-none">
            <p className="text-sm text-muted-foreground">
              <Label label={row.description} />
            </p>
          </div>
        </div>
      </div>
      <ErrorMessage name={dataKey} />
    </div>
  )
}
