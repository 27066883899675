import { JsonLogicCondition, LocalizedText } from "./common";
import { ButtonStyleReference } from "./theme";

export type ButtonTheme = {
  style: ButtonStyleReference;
};

export type Action =
  | {
      id: string;
      type: "efsSimple";
      code: string;
      accountCode: string;
      actionDataForm: Record<string, string>;
      processDataForm: Record<string, string>;
    }
  | {
      id: string;
      type: "efsPromise";
      code: string;
      accountCode: string;
      actionDataForm: Record<string, string>;
      processDataForm: Record<string, string>;
      promiseAmount: string;
      promiseDate: string;
      promisePaymentType: string;
    }
  | {
      id: string;
      type: "efsSettlement";
      code: string;
      accountCode: string;
      actionDataForm: Record<string, string>;
      processDataForm: Record<string, string>;
      installments: {
        amount: string;
        date: string;
      }[];
    };

export type ObjectValue = {
  id: string;
  key: string;
  value: string;
};

export type ActionEditType =
  | {
      id: string;
      type: "efsSimple";
      code: string;
      actionDataForm: ObjectValue[];
      processDataForm: ObjectValue[];
    }
  | {
      id: string;
      type: "efsPromise";
      code: string;
      actionDataForm: ObjectValue[];
      processDataForm: ObjectValue[];
      promiseAmount: string;
      promiseDate: string;
      promisePaymentType: string;
    }
  | {
      id: string;
      type: "efsSettlement";
      code: string;
      actionDataForm: ObjectValue[];
      processDataForm: ObjectValue[];
      installments: {
        amount: string;
        date: string;
      }[];
    };

export type Url = {
  path: string;
  jsonLogicCondition: JsonLogicCondition;
  actions: Action[];
};

export enum ButtonView {
  TEXT_LINK = "textlink",
  BUTTON = "button",
}

export enum ButtonIconPosition {
  START = "start",
  END = "end",
  NO_ICON = "no-icon",
}

export type ButtonIconType = {
  name: string;
  position: ButtonIconPosition;
};

export type ButtonType = {
  text: string;
  hidden?: boolean;
  view: ButtonView;
  icon?: ButtonIconType;
  theme: ButtonTheme;
  urls: Url[];
};

export type ButtonEditType = {
  id: string;
  localizedText: LocalizedText;
  view: ButtonView;
  icon?: ButtonIconType;
  hidden?: boolean;
  theme: ButtonTheme;
};
