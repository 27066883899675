import { FunctionComponent } from 'react'

import { InternalPageType } from '../../ui-library/types/page'
import { MainContent } from '../../content/main-content'
import { SideContent } from '../../content/side-content'

type Props = {
  page: InternalPageType
}

export const PageLayoutLeftBar: FunctionComponent<Props> = ({ page }) => {
  const { content } = page
  const { main, side } = content

  return (
    <div className="mx-auto">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/3">
          <SideContent content={side} />
        </div>
        <div className="w-full md:w-2/3">
          <MainContent content={main} />
        </div>
      </div>
    </div>
  )
}
