import { I18nextProvider } from 'react-i18next'
//import 'typeface-roboto'
import 'normalize.css'
import './theme/style.css'
import i18n from './i18n'
import App from './App'
import { StoreProvider } from './context'
import { initialState, actionReducer } from './context/actionReducer'
import reportWebVitals from './reportWebVitals'
import './index.css'

import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <I18nextProvider i18n={i18n}>
    <StoreProvider initialState={initialState} reducer={actionReducer}>
      <App />
    </StoreProvider>
  </I18nextProvider>
)

reportWebVitals()
